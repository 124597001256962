'use client';

import { isEmptyValue } from '@frontend/jetlend-core/src/validators';
import React, {
    MutableRefObject,
    forwardRef,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import { IConnectableInputProps } from '@frontend/jetlend-core/src/ui/inputs/common';
import { connectToField } from '@frontend/jetlend-core/src/ui/inputs/connect';
import {
    buildClassNames,
    mergeClassNames,
} from '@ui/utils/classNameUtils';

import styles from './inlineTextInput.module.scss';
import useInputControl from '@ui/hooks/useInputControl';

export type IProps = IConnectableInputProps & {
    id?: string;
    size?: 'small' | 'normal';
    label?: string;
    placeholder?: string;
    disabled?: boolean;
    inputType?: string;
    autoComplete?: boolean|string;
    autoFocus?: boolean;
    maxLength?: number;
    noMarginBottom?: boolean;
    postfix?: string | JSX.Element;
    icon?: string;
    footer?: JSX.Element | React.ReactNode;
    className?: string;
    inputRef?: MutableRefObject<HTMLInputElement|null>;
    onKeyDown?: (eventOrData: any) => void;
};

export const InlineTextInputComponent = forwardRef<HTMLDivElement, IProps>((props, ref) => {

    const {
        id,
        size = 'normal',
        name,
        value,
        placeholder,
        disabled,
        label,
        onChange,
        onFocus,
        onBlur,
        maxLength,
        noMarginBottom,
        icon,
        className,
    } = props;

    const [ focused, setFocused ] = useState<boolean>(false);

    // eslint-disable-next-line react-hooks/rules-of-hooks
    const inputRef = props.inputRef ?? useRef<HTMLInputElement>(null);

    useEffect(() => {
        if (inputRef.current) {
            if (document.activeElement === inputRef.current) {
                setFocused(true);
            }
        }
    }, [ inputRef ]);

    const didFocused = useCallback(e => {
        setFocused(true);

        onFocus && onFocus(e);
    }, [ onFocus ]);

    const didBlurred = useCallback(e => {
        setFocused(false);

        onBlur && onBlur(e);
    }, [ onBlur ]);

    useEffect(() => {
        if (props.submitFailed && inputRef.current) {
            inputRef.current.focus();
        }
    }, [ inputRef, props.submitFailed ]);

    const hasError = props.invalid && (props.touched && props.dirty || props.submitFailed);
    const error = props.error;
    const hasValue = !isEmptyValue(value) || !isEmptyValue(placeholder);
    const hasLabel = !isEmptyValue(label);

    const wrapperClassName = buildClassNames(styles, [
        'wrapper',
        noMarginBottom && 'wrapper--no-margin-bottom',
    ]);

    const containerClassName = mergeClassNames([
        buildClassNames(styles, [
            'container',
            size === 'small' && 'container--small',
            hasValue && hasLabel && 'container--has-value',
            focused && 'container--focused',
            hasError && 'container--error',
            disabled && 'container--disabled',
        ]),
        className,
    ]);

    const lastContainerClassName = buildClassNames(styles, [
        'container--last',
        focused && 'container--last--focused',
        (placeholder || hasValue || hasError) && 'container--placeholder',
    ]);

    const onChangeDefined = typeof onChange === 'function';

    const didInputChanged = useInputControl(value, inputRef, onChange);

    return (
        <label className={wrapperClassName}>
            <div ref={ref} className={containerClassName}>
                {icon && (
                    <div className={styles['img-wrapper']}>
                        <img src={icon} alt="" />
                    </div>
                )}
                <div className={lastContainerClassName}>
                    {hasLabel && (
                        <span className={styles['label']}>
                            <span className={styles['label__text']}>{label}</span>
                        </span>
                    )}
                    <div className={styles['input-wrapper']}>
                        <input
                            ref={inputRef}
                            id={id}
                            className={styles['input']}
                            name={name}
                            disabled={disabled}
                            value={onChangeDefined ? value ?? '' : undefined}
                            defaultValue={onChangeDefined ? undefined : value}
                            type={props.inputType}
                            placeholder={props.placeholder}
                            autoComplete={props.autoComplete === false ? 'off' : props.autoComplete?.toString()}
                            autoFocus={props.autoFocus}
                            maxLength={maxLength}
                            onChange={didInputChanged}
                            onFocus={didFocused}
                            onBlur={didBlurred}
                            onKeyDown={props.onKeyDown}
                        />
                    </div>
                </div>
                {props.postfix &&
                    typeof props.postfix === 'string'
                    ? <div className={styles['input__postfix']}>{props.postfix}</div>
                    : props.postfix
                }
            </div>
            {hasError && error &&
                <span className={styles['error']}>{error}</span>
            }
            {props.footer && (
                <div className={styles['footer']}>
                    {props.footer}
                </div>
            )}
        </label>
    );
});

const InlineTextInput = connectToField(InlineTextInputComponent);

export default InlineTextInput;